<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <rect
      width="68"
      height="68"
      rx="7"
      fill="#F5F6FB"
    />
    <path
      d="M32.4912 31.0963C32.3324 27.6844 29.5845 25 26.25 25C22.7964 25 20 27.9058 20 31.4398C20 33.3424 20.8204 35.1636 22.2362 36.3928L28.9714 42.218C31.2077 44.1522 32.5 46.9993 32.5 50C32.5 46.9993 33.7923 44.1522 36.0286 42.218L42.7638 36.3928C44.184 35.1591 45 33.3469 45 31.4398C45 27.9013 42.2036 25 38.75 25C35.4155 25 32.6676 27.6844 32.5088 31.0963"
      stroke="#ED166E"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
